<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card>
        <validation-observer ref="tableRules">
          <b-form>
            <b-row class="mb-2 mt-1" align-h="center">
              <b-col cols="12" md="8">
                <validation-provider
                  #default="{ errors }"
                  name="Table Name"
                  style="width: 100%;"
                  rules="required"
                >
                  <b-form-group label-for="title">
                    <b-form-input
                      id="title"
                      type="text"
                      v-model="table.name"
                      :state="errors.length > 0 ? false : null"
                      :trim="true"
                      placeholder="Table Name"
                      aria-describedby="input-live-feedback"
                    ></b-form-input>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="6"
                sm="12"
                md="6"
                v-for="(column, index) in columns"
                :key="index"
                class="mt-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Column Name"
                  style="width: 100%;"
                  rules="required"
                >
                  <b-form-group label-for="title">
                    <b-form-input
                      id="title"
                      type="text"
                      v-model="column.title"
                      :state="errors.length > 0 ? false : null"
                      :trim="true"
                      placeholder="Column Name"
                      aria-describedby="input-live-feedback"
                    ></b-form-input>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col cols="6" sm="12" md="6" class="mt-2">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-dark"
                  @click="addColumn"
                >
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  Add New Column
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <b-row align-h="end" class="mt-3">
          <b-col cols="3">
            <b-button
              @click="createTable()"
              block
              class="float-right"
              variant="success"
            >
              Create Table
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BImg,
  BPagination,
  BFormRating,
  BOverlay,
  BCard,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBToggle,
  BCollapse,
} from "bootstrap-vue";

import axiosIns from "@/libs/axios";
import store from "@/store";
import router from "@/router";
import { onUnmounted } from "@vue/composition-api";
import formulStoreModule from "../formulStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, alpha, positive } from "@validations";
import moment from "moment";
import _ from "lodash";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "animate.css";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormGroup,
    BPagination,
    BFormRating,
    BCollapse,
    moment,
    vSelect,
    flatPickr,
    ToastificationContent,

    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const FORMUL_APP_STORE_MODULE_NAME = "formul";
    // Register module
    if (!store.hasModule(FORMUL_APP_STORE_MODULE_NAME))
      store.registerModule(FORMUL_APP_STORE_MODULE_NAME, formulStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORMUL_APP_STORE_MODULE_NAME))
        store.unregisterModule(FORMUL_APP_STORE_MODULE_NAME);
    });
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  mounted() {
  },
  data() {
    return {
      loading: false,
      table: {
        name: null,
      },
      createTableSqlString: null,
      columns: [{ title: null }],
    };
  },
  methods: {
    createTable() {
      this.$refs.tableRules.validate().then((success) => {
        if (success) {
          //Set table create sql

          let columnSyntax = "";
          if (this.columns.length > 0) {
            this.columns.forEach((c) => {
              columnSyntax += `${c.title} VARCHAR(30) NULL,`;
            });
          }
          this.createTableSqlString = `CREATE TABLE ${this.table.name} (id INT(6) UNSIGNED AUTO_INCREMENT PRIMARY KEY,${columnSyntax}date TIMESTAMP DEFAULT CURRENT_TIMESTAMP ON UPDATE CURRENT_TIMESTAMP)`
          //********** */

          this.$store
            .dispatch("formul/checkTableName", {
              tableName: this.table.name,
            })
            .then((res) => {
              if (res.data.existing == 1) {
                this.checkTitleToastMessage();
              } else {
                this.$store
                  .dispatch("formul/createTable", {
                    newTable: this.createTableSqlString,
                  })
                  .then((res) => {
                    this.createTableSqlString = null
                    this.table.name = null
                    this.columns = [{ title: null }]
                    this.toastMessage('Table')
                    router.push({name: 'table-list',});
                  })
                  .catch((error) => {
                    console.log("error create table ", error);
                  });
              }
            })
            .catch((error) => {
              console.log("error create table ", error);
            });
        }
      });
    },
    addColumn() {
      this.columns.push({ title: null });
    },

    checkTitleToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: "This table name is used. Please check Table Name",
          icon: "AlertCircleIcon",
          variant: "warning",
        },
      });
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successful",
          text: "✔️ " + val + " created Successful",
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
  },
};
</script>
